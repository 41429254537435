import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { AdsGETApi, AdsGETApiAuth, AdsPOSTApi, ExamBlockPlaceHolder, NodePOSTApi, NodePOSTApiAuth } from '../../../Constant/api';
import { slugs } from '../../../Constant/slugs';
import { logout } from '../../../redux/action';
import NotFound from '../../NotFound';
import Chapters from './Chapters';
import store from '../../../redux/store';

const slug = {
  "ubt": "UBT",
  "cbt-chemical": "CBT CHEMICAL",
  "cbt-electronics-electricity": "CBT ELECTRONIC ELECTRCITY",
  "cbt-food-related": "CBT FOOD RELATED",
  "cbt-mechinery-molding": "CBT MACHINERY,MOLDING",
  "cbt-metal": "CBT METAL",
  "cbt-pulp-paper-wood": "CBT PULP PAPER WOOD",
  "cbt-rubber": "CBT RUBBER",
  "cbt-textile-sewing": "CBT TEXTILE SEWING"
}
export default function StartCourse() {
  const [AllExamList1, setAllExamList1] = React.useState([]);
  const [NotFoundData, setNotFound] = React.useState(false)
  const [ExamFetch, setExamFetch] = React.useState(false)
  const [AllNotesList, setAllNotesList] = React.useState([]);
  const [CourseContent, setCourseContent] = React.useState(
    {
      meta: { zoom_link: "", whatsapp_group: "" }
    }
  );
  const [CourseContentList, setCourseContentList] = React.useState([]);
  const [AllExamListUnavailable, setAllExamListUnavailable] = React.useState([]);
  const [reload, setReload] = React.useState(false)
  const LoginState = useSelector((state) => state.LoginState);
  const [isEmulator, setisEmulator] = React.useState(false);


  const location = useLocation()
  var slug = location.pathname.slice(slugs.start_course.length + 1);

  const fetchExam = (setAllExamList, perPage) => {
    const id = store.getState().LoginState.userID
    setReload(true)
    NodePOSTApi({
      userID: id,
      slug: slug
    }, "batches-details-class")
      .then(res => {
        // console.log(JSON.stringify(res.data))
        setReload(false)
        if (!res.data.error) {
          setAllExamList(res.data.exam_data.filter(a => a.meta.available_unavailable == "Available"))
          setAllNotesList(res.data.chapter_data)
          setCourseContent(res.data.batch_data[0])
          var content =
            res.data.batch_data[0].meta.course_content != "" ?
              Object.values(JSON.parse(res.data.batch_data[0].meta.course_content))
              :
              []
          setCourseContentList(content)
          setExamFetch(true)
          setNotFound(false)
        }
        else {
          setExamFetch(true)
          setNotFound(true)
        }
      })
  }
  useEffect(() => {
    fetchExam(setAllExamList1, {
      per_page: 100,
      order: "asc",
      _fields: "id,title,meta.featured_image,meta.available_unavailable,slug,meta.exam_question_block_student",
    })
  }, [])

  return (
    <div style={{ margin: "3px", backgroundColor: "#fff", minHeight: "100vh" }}>
      {ExamFetch &&
        <>
          {NotFoundData ?
            <NotFound /> :
            <>
              <div className="row">
                <div className="col-1"></div>
                <div className="col-10 mt-3">

                  <div className="row" style={{ margin: "10px", borderBottom: "1px solid #eee" }}>
                    {/* <h2>Course Information</h2> */}
                    <h2>{CourseContent.meta.batch_name}</h2>
                    {/* {JSON.stringify(CourseContent)} */}
                  </div>
                </div>
              </div>
              {/* <Chapters
                ChapterData={AllNotesList}
              /> */}
              {AllExamList1.length !== 0 &&
                <div style={{ marginVertical: 10 }}>

                  <span>FeedBack MCQs</span>
                  <div className='row'>
                    {AllExamList1.map((item, index) => (
                      <div key={index} className='col-md-3' >
                        <>
                          <ExamBlock item={item}
                            examSlug={slugs.start_exam}
                          />

                          {/* {isEmulator ?
                          <ExamBlock item={item} db_slug={db_slug} result_slug={result_slug} navigation={navigation}
                            navigationTo={`${slugs.start_demo_exam}emu`} />
                          :
                          <ExamBlock item={item} db_slug={db_slug} result_slug={result_slug} navigation={navigation}
                            navigationTo={slugs.start_demo_exam} />
                        } */}
                        </>
                      </div>
                    ))}
                  </div>
                </div>
              }
            </>
          }
        </>
      }
      {reload &&
        <div className="modal" style={{ padding: "50vw" }}>
          <div className="dot-pulse"></div>
        </div>
      }
    </div>
  )
}



const ExamBlock = ({ item, examSlug }) => {
  const location = useLocation();
  return (
    <div style={{
       margin: "5px 0px", 
      border: "1.5px solid #bbb",
      borderRadius: "5px",
    }}>
      {/* {item.meta.featured_image !== "" ?
        <img className="col-12" src={item.meta.featured_image}
          style={{ objectFit: 'contain', height: "100px", borderRadius: "3px" }} />
        :
        <img className="col-12"
          src={ExamBlockPlaceHolder}
          style={{ objectFit: 'contain', height: "100px", borderRadius: "3px" }} />
      } */}
      <br />
      <Link className="col-8 Link"
        to={`${examSlug}/${item.slug}`}
      >
        <div style={{ fontWeight: "bold", textAlign: "center",fontSize:"18px" }}>
          {item.title.rendered}&nbsp;
        </div>
        {/* {(!JSON.stringify(location).includes("all-demo-exam-list") && item.meta.exam_question_block_student == undefined) ?
          <div style={{ width: "100%", display: 'flex', justifyContent: 'center' }}>
            <div className="sign-in-button-1 col-8 Link" style={{ color: "#fff", opacity: 0.5 }}
            >
              Verify to Start
            </div>
          </div>
          :
          <div style={{ width: "100%", display: 'flex', justifyContent: 'center' }}>
            {item.meta.available_unavailable == "Available" ?
              <Link className="sign-in-button-1 col-8 Link"
                to={`${examSlug}/${item.slug}`}
              >
                <div style={{ color: "#fff" }}>
                  Start Exam
                </div>
              </Link>
              :
              <div className="sign-in-button-1 col-8 Link" style={{ color: "#000", backgroundColor: "#fff" }}>
                Exam Unavailable
              </div>
            }

          </div>
        } */}
      </Link>

    </div >
  )

}