import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { AdsGETApiAuth, NodePOSTApi } from '../../../Constant/api';
import { slugs } from '../../../Constant/slugs';
import store from '../../../redux/store';
import NotFound from '../../NotFound';
import FirstScreenExam from '../ExamDetails';
import { logout } from '../../../redux/action';
import { FullScreen, useFullScreenHandle } from "react-full-screen";

export default function StartMyExam(props) {
  const dispatch = useDispatch()
  const location = useLocation()
  const [ExamData, setAllExamList] = React.useState({});
  const [ExamFetch, setExamFetch] = React.useState(false);
  const [NotFoundData, setNotFound] = React.useState(false)
  const [reload, setReload] = React.useState(false)
  const [choose, setChoose] = React.useState(0)
  const [AnswerArray, setAnswerArray] = React.useState([]);
  const [ResultData, setResultData] = React.useState({});
  const LoginState = useSelector((state) => state.LoginState);
  const [usersData, setUsersData] = React.useState({ meta: { number_of_exams: "0" } });

  const fetchExam2 = (slug, verifiedExamList) => {
    setExamFetch(false)
    setReload(true)
    setNotFound(false)
    const id = store.getState().LoginState.userID
    NodePOSTApi({ userID: id, examSlug: slug }, "start-exam-details")
      .then(res => {
        if (res.data.redirect) {
          // history.push(`${slugs.exam_payment}/${slug}`)
        }
        else {
          if (res.data.result_data.length !== 0) {
            setChoose(2)
            setAnswerArray(JSON.parse(res.data.result_data[0].meta.exam_answers_array_results))
            setResultData(res.data.result_data[0])
          }
          setAllExamList(res.data.exam_data[0])
          setExamFetch(true)
        }
      })
      .finally(() => {
        setReload(false)
      })
      .catch(err => {
        console.log(err)
        console.log(err.response.data)
      })

  }
  const [finishStatus, setfinishStatus] = React.useState(false);
  const handle = useFullScreenHandle();

  const fetchExam = (slug) => {
    setExamFetch(false)
    const id = store.getState().LoginState.userID
    AdsGETApiAuth({ slug: slug }, slugs.db_slug_paid_exam)
      .then((res) => {
        if (res.data.length == 1) {
          setAllExamList(res.data[0])
          setExamFetch(true)
          setReload(false)
        }
        else {
          setExamFetch(true)
          setReload(false)
        }
      })
      .catch(err => {
        console.log(err)
        console.log(err.response.data)
      })
  }
  useEffect(() => {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    }
    else
      handle.enter()
    var slug = location.pathname.slice(slugs.start_exam.length + 1);
    fetchExam(slug)

  }, [])

  return (
    <div>
      <FullScreen handle={handle}>
        <div style={{ backgroundColor: "#eee", minHeight: "100vh" }}>
          <div>
            <div 
            // className="form-view"
            >
              {ExamFetch &&
                <>
                  {NotFoundData ?
                    <NotFound /> :
                    <>
                      <FirstScreenExam
                        ExamData={ExamData}
                        setReload={setReload}
                        choose={choose}
                        setChoose={setChoose}
                        AnswerArray={AnswerArray}
                        setAnswerArray={setAnswerArray}
                        ResultData={ResultData} setResultData={setResultData}
                      />
                    </>
                  }
                </>
              }
            </div>
          </div>
          {reload &&
            <div className="modal" style={{ padding: "50vw" }}>
              <div className="dot-pulse"></div>
            </div>
          }
        </div>
      </FullScreen>
    </div>
  )
}