import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { AdsGETApiAuth, AdsPOSTApi, UserPlaceHolder, sideBarImage } from '../../../Constant/api';
import { slugs } from '../../../Constant/slugs';
import store from '../../../redux/store';
import SecondScreenComponent from './GetStartedPage';
import FinalScreenExam from './MarksObtainedPage';
import './styles.css'
import axios from 'axios';
var nodejsurl = "https://real-exam.epsexam.com/base64"

export default function FirstScreenExam(props) {
  const {
    ExamData,
    setReload,
    choose, setChoose,
    AnswerArray, setAnswerArray,
    ResultData, setResultData
  } = props;
  const LoginUser = useSelector((state) => state.LoginState);
  const history = useHistory();
  const location = useLocation();
  const [QuestionsData, setQuestionsData] = React.useState(
    JSON.stringify(location).includes("start-demo-exam") ?
      JSON.parse(ExamData.meta.exam_question_text) :
      JSON.parse(ExamData.meta.exam_question_block_text))
  const [QuestionsEditMode, setQuestionsEditMode] = React.useState(true);
  const [QuestionsEditData, setQuestionsEditData] = React.useState(
    JSON.stringify(location).includes("start-demo-exam") ?
      JSON.parse(ExamData.meta.exam_question_text)["item-0"] :
      JSON.parse(ExamData.meta.exam_question_block_text)["item-0"]
  )
  const [PlaySingle, setPlaySingle] = React.useState(ExamData.meta.media_non_repeat);
  const [QuestionsEditKey, setQuestionsEditKey] = React.useState("item-0");
  const [TimingStarted, setTimingStarted] = React.useState(false);
  const [userDetails, setUserDetails] = React.useState({})
  const [obtainedMarks, setObtainedMarks] = React.useState(0)
  const [mediaPlayedJSON, setMediaPlayed] = React.useState({})
  const [batchExpired, setBatchExpired] = React.useState({})
  document.onkeydown = function (e) {
    if (e.key === 'F12') {
      return false;
    }
    if (e.ctrlKey && e.shiftKey && e.key === 'I') {
      return false;
    }
    if (e.ctrlKey && e.shiftKey && e.key === 'C') {
      return false;
    }
    if (e.ctrlKey && e.shiftKey && e.key === 'J') {
      return false;
    }
    if (e.ctrlKey && e.key === 'u') {
      return false;
    }
  }
  useEffect(() => {
    var mediaPlayed = {};
    typeof window !== undefined &&
      window.document.addEventListener("contextmenu", (e) => {
        e.preventDefault();
      });
    const ArrayValue = JSON.stringify(location).includes(slugs.start_demo_exam) ?
      Object.values(JSON.parse(ExamData.meta.exam_question_text)) :
      Object.values(JSON.parse(ExamData.meta.exam_question_block_text))
    for (let i = 0; i < ArrayValue.length; i++) {
      const QuestionJSON = ArrayValue[i]
      mediaPlayed = {
        ...mediaPlayed,
        [`question-${i + 1}`]: "0",
        [`answer-${i + 1}-a`]: "0",
        [`answer-${i + 1}-b`]: "0",
        [`answer-${i + 1}-c`]: "0",
        [`answer-${i + 1}-d`]: "0",
      }
      setMediaPlayed(mediaPlayed)
      if (AnswerArray.length < ArrayValue.length)
        AnswerArray.push({
          question_number: QuestionJSON.question_number,
          correct_answer: QuestionJSON.correct_answer,
          answerChoosen: "",
          solved: 0
        })
    }
    downloadMedia()
  }, [])
  const SubmitExam = () => {
    setReload(true)
    const token = store.getState().LoginState.token
    const id = store.getState().LoginState.userID;
    var correct = 0;
    const date = new Date();
    const month = date.getMonth() < 9 ? `0${date.getMonth() + 1}` : `${date.getMonth() + 1}`
    const day = date.getDate() < 9 ? `0${date.getDate()}` : `${date.getDate()}`
    for (let i = 0; i < AnswerArray.length; i++) {
      if (AnswerArray[i].correct_answer == AnswerArray[i].answerChoosen)
        correct++
    }
    const data = {
      title: `Exam-${ExamData.title.rendered} User-${id} Date-${date.getUTCFullYear()}/${month}/${day}`,
      status: "publish",
      meta: {
        "exam_answers_array_results": JSON.stringify(AnswerArray),
        "exam_date_results": `${date.getUTCFullYear()}-${month}-${day}`,
        "exam_name_results": `${ExamData.title.rendered}`,
        "exam_id_results": `${ExamData.id}`,
        "exam_total_results": `${correct}`,
        "student_id_results": `${id}`,
        "student_name_results": `${LoginUser.userName}`,
        "student_phone_results": `${LoginUser.phoneNumber}`
      }
    }
    if (location.pathname.includes(slugs.start_exam))
      AdsPOSTApi(data, slugs.db_slug_paid_results)
        .then((res) => {
          setReload(false)
          setResultData(res.data)
          // setChoose(4)
          setChoose(2)
        }).catch((err) => {
        })
    else if (location.pathname.includes(slugs.start_daily_exam))
      AdsPOSTApi(data, slugs.db_slug_daily_results)
        .then((res) => {
          setReload(false)
          setResultData(res.data)
          // setChoose(4)
          setChoose(2)
        }).catch((err) => {
        })
    if (location.pathname.includes(slugs.start_chapter_wise_exam))
      AdsPOSTApi(data, slugs.db_slug_chapter_results)
        .then((res) => {
          setReload(false)
          setResultData(res.data)
          // setChoose(4)
          setChoose(2)
        }).catch((err) => {
        })
    else {
      setReload(false)
      setChoose(4)
    }
  }
  const downloadMedia = () => {
    const ArrayValue = JSON.stringify(location).includes(slugs.start_demo_exam) ?
      Object.values(JSON.parse(ExamData.meta.exam_question_text)) :
      Object.values(JSON.parse(ExamData.meta.exam_question_block_text))
    const ArrayKeys = JSON.stringify(location).includes(slugs.start_demo_exam) ?
      Object.keys(JSON.parse(ExamData.meta.exam_question_text)) :
      Object.keys(JSON.parse(ExamData.meta.exam_question_block_text))
    var QuestionDataCache = QuestionsData;
    var option1 = [];
    var option2 = [];
    var option3 = [];
    var option4 = [];
    var question = [];
    var question2 = [];
    var url1 = [];
    var url2 = [];
    var url3 = [];
    var url4 = [];
    var url5 = [];
    var url6 = [];

    for (let i = 0; i < ArrayValue.length; i++) {
      const QuestionJSON = ArrayValue[i]
      option1[i] = ArrayValue[i].option_1;
      option2[i] = ArrayValue[i].option_2;
      option3[i] = ArrayValue[i].option_3;
      option4[i] = ArrayValue[i].option_4;
      question[i] = ArrayValue[i].question_description;
      question2[i] = ArrayValue[i].question_media;

      url1[i] = ArrayValue[i].option_1
      url2[i] = ArrayValue[i].option_2
      url3[i] = ArrayValue[i].option_3
      url4[i] = ArrayValue[i].option_4
      url5[i] = ArrayValue[i].question_description
      url6[i] = ArrayValue[i].question_media

      if (ArrayValue[i].answer_media_type == "audio") {
        if (
          url1[i].includes("https://") &&
          url2[i].includes("https://") &&
          ArrayValue[i].option_3.includes("https://") &&
          ArrayValue[i].option_4.includes("https://")
        ) {
          axios({ method: "GET", url: `${nodejsurl}?url=${url1[i]}` })
            .then(res => {
              if (res.data.success)
                option1[i] = `data:audio/mpeg;base64,${res.data.data}`
              axios({ method: "GET", url: `${nodejsurl}?url=${url2[i]}` })
                .then(res => {
                  if (res.data.success)
                    option2[i] = `data:audio/mpeg;base64,${res.data.data}`
                  axios({ method: "GET", url: `${nodejsurl}?url=${url3[i]}` })
                    .then(res => {
                      if (res.data.success)
                        option3[i] = `data:audio/mpeg;base64,${res.data.data}`
                      axios({ method: "GET", url: `${nodejsurl}?url=${url4[i]}` })
                        .then(res => {
                          if (res.data.success)
                            option4[i] = `data:audio/mpeg;base64,${res.data.data}`
                          if (ArrayValue[i].question_description_type == "audio")
                            axios({ method: "GET", url: `${nodejsurl}?url=${url5[i]}` })
                              .then(res => {
                                if (res.data.success)
                                  question[i] = `data:audio/mpeg;base64,${res.data.data}`
                                QuestionDataCache = {
                                  ...QuestionDataCache,
                                  [ArrayKeys[i]]: {
                                    ...QuestionJSON,
                                    "question_description": question[i],
                                    "option_1": option1[i],
                                    "option_2": option2[i],
                                    "option_3": option3[i],
                                    "option_4": option4[i],
                                  }
                                }
                                setQuestionsData(QuestionDataCache)
                              }).catch(() => { })
                          if (ArrayValue[i].question_media_type == "audio")
                            axios({ method: "GET", url: `${nodejsurl}?url=${url5[i]}` })
                              .then(res => {
                                if (res.data.success)
                                  question[i] = `data:audio/mpeg;base64,${res.data.data}`
                                QuestionDataCache = {
                                  ...QuestionDataCache,
                                  [ArrayKeys[i]]: {
                                    ...QuestionJSON,
                                    "question_media": question[i],
                                    "option_1": option1[i],
                                    "option_2": option2[i],
                                    "option_3": option3[i],
                                    "option_4": option4[i],
                                  }
                                }
                                setQuestionsData(QuestionDataCache)
                              }).catch(() => { })
                        }).catch(() => { })


                    }).catch(() => { })

                }).catch(() => { })

            })
        }
      }
      else {
        if (ArrayValue[i].question_description_type == "audio")
          axios({ method: "GET", url: `${nodejsurl}?url=${url5[i]}` })
            .then(res => {
              if (res.data.success)
                question[i] = `data:audio/mpeg;base64,${res.data.data}`
              QuestionDataCache = {
                ...QuestionDataCache,
                [ArrayKeys[i]]: {
                  ...QuestionJSON,
                  "question_description": question[i],
                }
              }
              setQuestionsData(QuestionDataCache)
            }).catch(() => { })
        if (ArrayValue[i].question_media_type == "audio")
          axios({ method: "GET", url: `${nodejsurl}?url=${url6[i]}` })
            .then(res => {
              if (res.data.success)
                question2[i] = `data:audio/mpeg;base64,${res.data.data}`
              QuestionDataCache = {
                ...QuestionDataCache,
                [ArrayKeys[i]]: {
                  ...QuestionJSON,
                  "question_media": question2[i],
                }
              }
              setQuestionsData(QuestionDataCache)
            }).catch(() => { })
      }
      // if (i == ArrayValue.length - 1) { setTimeout(() => { setReload2(false) }, 5000) }
    }
  }
  return (
    <div>
      {/* <div className=" d-sm-block d-none" style={{userSelect:"none"}}> */}
      <div style={{ userSelect: "none" }}>
        <div style={{ position: "relative" }}>

          {choose == 0 &&
            <div style={{ display: 'flex', justifyContent: 'center', padding: "30px" }}>
              <div style={{ width: "100%" }}>
                <div style={{ textAlign: 'center' }}>
                  <h1>{ExamData.title.rendered}</h1>
                  <br />
                  {LoginUser.userPhoto === undefined ?
                    <img src={UserPlaceHolder}
                      height="30px"
                    />
                    :
                    <img src={`${LoginUser.userPhoto}`} className={"height-width-150 border border-dark"} />
                  }
                </div>
                <div>
                  <br />
                  <b>Name of Student: {LoginUser.userName}</b>
                  <br />
                  <b>Student Email: {LoginUser.userEmail}</b>
                  <br />
                </div>
                <div>
                  <b>Exam description</b>
                  <br />
                  <div dangerouslySetInnerHTML={{ __html: ExamData.meta.exam_details }} />
                  {/* {ExamData.content.rendered} */}
                </div>
                <br />
                <div className="row">
                  <div className="col-lg-4 col-12" />
                  <div className="col-lg-4 col-12 center">
                    <button className="sign-in-button-11 col-lg-8 col-7"
                      onClick={() => {
                        setChoose(1)
                        setTimingStarted(true)
                      }}
                    >
                      Get Started
                    </button>
                    <button className="sign-in-button-4 col-lg-6 col-6"
                      onClick={() => {
                        history.goBack()
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          }

          {choose == 1 &&
            <div
              className='question-view-main'
            >
              <div className='question-width'>
                <SecondScreenComponent
                  QuestionsEditKey={QuestionsEditKey} setQuestionsEditKey={setQuestionsEditKey}
                  QuestionsEditData={QuestionsEditData} setQuestionsEditData={setQuestionsEditData}
                  QuestionsData={QuestionsData} setQuestionsData={setQuestionsData}
                  QuestionsEditMode={QuestionsEditMode} setQuestionsEditMode={setQuestionsEditMode}
                  TimingStarted={TimingStarted} setTimingStarted={setTimingStarted}
                  ExamData={ExamData}
                  AnswerArray={AnswerArray}
                  setChoose={setChoose}
                  SubmitExam={SubmitExam}
                  mediaPlayedJSON={mediaPlayedJSON}
                  PlaySingle={PlaySingle}
                />
              </div>
            </div>
          }
          {choose == 2 &&
            <div>
              <FinalScreenExam
                ExamData={ExamData}
                QuestionsData={QuestionsData}
                AnswerArray={AnswerArray}
                userDetails={userDetails}
                ResultData={ResultData}
              />
            </div>
          }
        </div>

      </div>
      {/* <div className="d-sm-none d-block">
        <div style={{ display: 'flex', justifyContent: 'center', padding: "30px" }}>
          <div style={{ width: "100%" }}>
            <div style={{ textAlign: 'center' }}>
              <h1>{ExamData.title.rendered}</h1>
              <br />
              {LoginUser.userPhoto === undefined ?
                <img src={UserPlaceHolder}
                  height="30px"
                />
                :
                <img src={`${LoginUser.userPhoto}`} className={"height-width-150 border border-dark"} />
              }
            </div>
            <div>
              <br />
              <b>Name of Student: {LoginUser.userName}</b>
              <br />
              <b>Student Email: {LoginUser.userEmail}</b>
              <br />
            </div>
            <div>
              <b>Exam description</b>
              <br />
              <div dangerouslySetInnerHTML={{ __html: ExamData.meta.exam_details }} />
            </div>
            <div style={{ fontWeight: "bold" }}>
              The Exam will be best suitable for landscape view.<br />
              Please Rotate your screen
            </div>
            <br />
            <div className="row">
              <div className="col-lg-4 col-12" />
              <div className="col-lg-4 col-12 center">
                <button className="sign-in-button-4 col-lg-6 col-6"
                  onClick={() => {
                    history.goBack()
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>


      </div> */}

    </div>
  )
}
