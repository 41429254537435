
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { AdsGETApi, AdsGETApiAuth, AdsPOSTApi, ExamBlockPlaceHolder, NodePOSTApi } from '../../../Constant/api';
import { slugs } from '../../../Constant/slugs';
import { logout } from '../../../redux/action';

const slug = {
  "ubt": "UBT",
  "cbt-chemical": "CBT CHEMICAL",
  "cbt-electronics-electricity": "CBT ELECTRONIC ELECTRCITY",
  "cbt-food-related": "CBT FOOD RELATED",
  "cbt-mechinery-molding": "CBT MACHINERY,MOLDING",
  "cbt-metal": "CBT METAL",
  "cbt-pulp-paper-wood": "CBT PULP PAPER WOOD",
  "cbt-rubber": "CBT RUBBER",
  "cbt-textile-sewing": "CBT TEXTILE SEWING"
}

export default function CourseCategory() {

  const [reload, setReload] = React.useState(false)
  const LoginState = useSelector((state) => state.LoginState);
  const [AllExamList1, setAllExamList1] = React.useState([]);
  const [AllExamListUnavailable, setAllExamListUnavailable] = React.useState([]);
  const [AllOrders, setAllOrders] = React.useState([]);

  const fetchExam = (setAllExamList, perPage) => {
    setReload(true)
    const date = new Date()
    NodePOSTApi({ userID: LoginState.userID }, "my-batches")
      .then(res => {
        setAllOrders(res.data.my_batches)
        setAllExamList(res.data.batch_data)
        setReload(false)
      })
  }
  useEffect(() => {
    fetchExam(setAllExamList1, {
      per_page: 100,
      order: "asc",
      _fields: "id,title,meta,slug"
    })
    // Orientation.lockToPortrait()

  }, [])
  const location = useLocation()
  var parent_id = location.pathname.slice(slugs.category_courses.length + 1);



  return (
    <div>
      <div className="row">
        <div className="col-1"></div>
        <div className="col-10 form-view mt-3">
          <div className="row" style={{ margin: "10px" }}>
            {/* <h2>Categories</h2> */}
            {/* {AllExamList1[1].meta.parent_id} */}
            {AllExamList1.filter(a => a.meta.category_id == parent_id).map((item, index) => (
              // {AllExamList1.map((item, index) => (
              <div key={index} className="col-lg-3 col-md-4 col-12 center" style={{ padding: "0 5px" }}>
                <ExamBlock item={item} examSlug={slugs.start_course}
                  AllOrders={AllOrders}
                />
              </div>
            ))}
          </div>


        </div>
      </div>

      {reload &&
        <div className="modal" style={{ padding: "50vw" }}>
          <div className="dot-pulse"></div>
        </div>
      }
    </div>
  )
}

const ExamBlock = ({ item, examSlug, AllOrders }) => {
  const location = useLocation();
  const date = new Date()
  const month = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"]
  const getDate = date.getDate() <= 9 ? `0${date.getDate()}` : date.getDate()
  const dateTody = `${date.getFullYear()}-${month[date.getMonth()]}-${getDate}`

  const MyBatchFound = AllOrders.filter(a =>
    a.meta.batch_id_order == item.id &&
    a.meta.verification_status == "Verified" &&
    a.meta.verification_date > dateTody
  )

  const [check, setCheck] = React.useState(false)
  useEffect(() => {
    // console.log(item,item.meta.batch_price_list)
    // if (item.meta.batch_price_list !== "")
      if (MyBatchFound.length !== 0 || Object.values(JSON.parse(item.meta.batch_price_list)).length == 0)
        setCheck(true)
  }, [])
  return (
    <a
      href={
        check ?
          `${examSlug}/${item.slug}`
          :
          "#"
      }>
      <div style={{
        padding: "5px", margin: "5px 0px",
        border: !check ? "1px solid #bbb" : "2px solid #02bd02",
        borderRadius: "5px",
      }}>
        {item.meta.featured_image !== "" ?
          <img className="col-12" src={item.meta.featured_image}
            style={{ objectFit: 'contain', height: "100px", borderRadius: "3px" }} />
          :
          <img className="col-12"
            src={ExamBlockPlaceHolder}
            style={{ objectFit: 'contain', height: "100px", borderRadius: "3px" }} />
        }
        <br />
        <div style={{ color: "#000", fontWeight: !check ? "normal" : "bold" }}>
          {item.title.rendered}&nbsp;
        </div>
        {/* <div style={{ width: "100%", display: 'flex', justifyContent: 'center' }}>
          <Link className="sign-in-button-1 col-8 Link"
            to={`${examSlug}/${item.slug}`}
          >
            <div style={{ color: "#fff" }}>
              Open Course
            </div>
          </Link>
        </div> */}
      </div >
    </a>
  )

}