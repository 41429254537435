import React, { useEffect } from "react";
import { Link, useHistory, useLocation } from 'react-router-dom';
import { AdsGETApiAuth, AdsPOSTApi, AdsPUTApi } from "../../../../Constant/api";
import { slugs } from "../../../../Constant/slugs";
import { AlertComponent } from "../../../AlertComponent";
import { CustomButton } from "../../../CustomButtom";
import FileUpload from "../../../FileUpload";

export default function FormComponent(props) {
  const { FetchData } = props;
  const history = useHistory()
  const [ID, setID] = React.useState(FetchData.id)

  const [Title, setTitle] = React.useState(FetchData.title.rendered)
  const [BatchName, setBatchName] = React.useState(FetchData.meta.batch_name)
  const [BatchPrice, setBatchPrice] = React.useState(FetchData.meta.batch_price)
  const [BatchStartDate, setBatchStartDate] = React.useState(FetchData.meta.batch_start_date)
  const [BatchEndDate, setBatchEndDate] = React.useState(FetchData.meta.batch_end_date)
  const [BatchStatus, setBatchStatus] = React.useState(FetchData.meta.batch_status)
  const [BatchPriceList, setBatchPriceList] = React.useState(FetchData.meta.batch_price_list == "" ? [] : JSON.parse(FetchData.meta.batch_price_list))
  const [Type, setType] = React.useState(FetchData.meta.type)

  const [reload, setReload] = React.useState(false)

  const [AlertShow, setAlertShow] = React.useState(false)
  const [Alertcomp, setAlertcomp] = React.useState("")

  const type_array = {
    "First_Paper": "MCQ",
    "Second_Paper": "Written",
    "Online_Class": "Class"
  }
  const EditInfo = () => {
    setReload(true)
    const data = {
      "title": Title,
      "status": "publish",
      "meta": {
        "batch_name": Title,
        "batch_start_date": BatchStartDate,
        "batch_end_date": BatchEndDate,
        "batch_status": BatchStatus,
        "chapters": JSON.stringify(Chapters),
        "exam_list": JSON.stringify(BatchExam),
        "type": Type,
        "category_id": `${CategoryID}`,
        "category_name": CategoryName,
        "batch_price": `${BatchPrice}`,
        "featured": `${BatchPrice}`,
        "batch_price_list": JSON.stringify(BatchPriceList),
        "course_content": JSON.stringify(CourseContent),
        "zoom_link": Zoom,
        "whatsapp_group": WhatsappLink
      }
    }

    if (ID === "")
      AdsPOSTApi(data, slugs.db_slug_batch)
        .then((res) => {
          setReload(false)
          setID(res.data.id)
          setAlertShow(true)
          setAlertcomp("Batch Added Successfully")
          history.push(`${slugs.batch_details}${res.data.slug}`)
        }).catch(err => {
          setReload(false)
          console.log(err)
          setAlertShow(true)
          setAlertcomp(err.response.data.message)
        })
    else
      AdsPUTApi(data, slugs.db_slug_batch, ID)
        .then((res) => {
          setReload(false)
          setAlertShow(true)
          setAlertcomp(`${Title} edited Successfully`)
        }).catch(err => {
          console.log(err)
          console.warn(err.response.data)
          console.log("Response Status: hello11", err.response.status);
          console.log("Response Headers:", err.response.headers);
          setReload(false)
          setAlertShow(true)
          setAlertcomp(err.response.data.message)
        })
  }
  const [CategoryModal, setCategoryModal] = React.useState(false)
  const [CategoryID, setCategoryID] = React.useState(FetchData.meta.category_id)
  const [CategoryName, setCategoryName] = React.useState(FetchData.meta.category_name)
  const [CategoriesList, setCategoriesList] = React.useState([])
  const GetAllCategories = () => {
    setReload(true)
    setCategoriesList([])
    AdsGETApiAuth({
      per_page: 100,
      _fields: "id,title,slug,meta"
    }, slugs.db_slug_category)
      .then((res) => {
        setCategoriesList(res.data)
        console.log("done")
      })
      .catch(err => {
        console.log(err)
      }).finally(() => {
        setReload(false)
      })
  }
  useEffect(() => {
    GetAllCategories()
    GetAllExam()
    GetAllChapters()
  }, [])

  const [ExamArray, setExamArray] = React.useState([])
  const [ExamArray1, setExamArray1] = React.useState([])
  const [BatchExam, setBatchExam] = React.useState(JSON.parse(FetchData.meta.exam_list))
  const [BatchExamCache, setBatchExamCache] = React.useState(JSON.parse(FetchData.meta.exam_list))

  const [BatchExamDeleteIndex, setBatchExamIndex] = React.useState("")
  const [BatchExamDeleteModal, setBatchExamDeleteModal] = React.useState(false)
  const [AddNew, setAddNew] = React.useState(false)
  const [serachName, setSearchName] = React.useState("")

  const [BatchPriceDeleteIndex, setBatchPriceIndex] = React.useState("")
  const [BatchPriceDeleteModal, setBatchPriceDeleteModal] = React.useState(false)


  const GetAllExam = () => {
    setReload(true)
    AdsGETApiAuth({
      per_page: 300,
      _fields: "id,title,slug,meta"
    }, slugs.db_slug_paid_exam)
      .then((res) => {
        setExamArray(res.data)
        setExamArray1(res.data)
        console.log("done")
      })
      .catch(err => {
        console.log(err)
      }).finally(() => {
        setReload(false)
      })
  }

  const MappingExam = ({ item }) => {
    const EnrollmentSearch = Object.values(BatchExamCache).filter(a => a.exam_id == item.id)
    const [CheckON, setCheckON] = React.useState(EnrollmentSearch.length == 1 ? true : false)
    return (
      <>
        <td onClick={() => {
          setCheckON(true)
          if (BatchExamCache.filter(a => a.exam_id == item.id).length == 0) {
            BatchExamCache.push({
              exam_id: item.id,
              featured_image: item.meta.featured_image,
              name: item.title.rendered,
              exam_type: item.meta.exam_type,
              slug: item.slug
            })
          }
        }} >
          {CheckON ?
            <i className="fa fa-check-square-o" />
            :
            <i className="fa fa-square-o" />
          }
        </td>
        <td>{item.id}</td>
        <td>{item.title.rendered}</td>
        <td style={{ cursor: "pointer" }} onClick={() => {
          setCheckON(true)
          if (BatchExamCache.filter(a => a.exam_id == item.id).length == 0) {
            BatchExamCache.push({
              exam_id: item.id,
              featured_image: item.meta.featured_image,
              name: item.title.rendered,
              exam_type: item.meta.exam_type,
              slug: item.slug
            })
          }
        }}
        >Choose</td>
      </>
    )
  }

  const [ChaptersArray, setChaptersArray] = React.useState([])
  const [ChaptersArray1, setChaptersArray1] = React.useState([])

  const [Chapters, setChapters] = React.useState(JSON.parse(FetchData.meta.chapters))
  const [ChaptersCache, setChaptersCache] = React.useState(JSON.parse(FetchData.meta.chapters))

  const [ChaptersDeleteIndex, setChaptersIndex] = React.useState("")
  const [ChaptersDeleteModal, setChaptersDeleteModal] = React.useState(false)
  const [AddNewChapters, setAddNewChapters] = React.useState(false)
  const [serachNameChapters, setSearchNameChapters] = React.useState("")
  const [Zoom, setZoomLink] = React.useState(FetchData.meta.zoom_link)
  const [WhatsappLink, setWhatsappLink] = React.useState(FetchData.meta.whatsapp_group)

  const [CourseContent, setCourseContent] = React.useState(FetchData.meta.course_content == "" ? [] : JSON.parse(FetchData.meta.course_content))
  const [CourseContentEdit, setCourseContentEdit] = React.useState(false)
  const [CourseEditData, setCourseEditData] = React.useState({})
  const [CourseEditKey, setCourseEditKey] = React.useState("")


  const GetAllChapters = () => {
    setReload(true)
    AdsGETApiAuth({
      per_page: 100,
      _fields: "id,title,slug,meta"
    }, slugs.db_slug_chapters)
      .then((res) => {
        setChaptersArray(res.data)
        setChaptersArray1(res.data)
        console.log("done")
      })
      .catch(err => {
        console.log(err)
      }).finally(() => {
        setReload(false)
      })
  }

  const MappingChapters = ({ item }) => {
    const EnrollmentSearch = Object.values(ChaptersCache).filter(a => a.id == item.id)
    const [CheckON, setCheckON] = React.useState(EnrollmentSearch.length == 1 ? true : false)
    return (
      <>
        <td onClick={() => {
          setCheckON(true)
          if (ChaptersCache.filter(a => a.id == item.id).length == 0) {
            ChaptersCache.push({
              id: item.id,
              featured_image: item.meta.featured_image,
              name: item.title.rendered,
              type: item.meta.type,
              slug: item.slug
            })
          }
        }}>
          {CheckON ?
            <i className="fa fa-check-square-o" />
            :
            <i className="fa fa-square-o" />
          }
        </td>
        <td>{item.id}</td>
        <td>{item.title.rendered}</td>
        <td style={{ cursor: "pointer" }} onClick={() => {
          setCheckON(true)
          if (ChaptersCache.filter(a => a.id == item.id).length == 0) {
            ChaptersCache.push({
              id: item.id,
              featured_image: item.meta.featured_image,
              name: item.title.rendered,
              type: item.meta.type,
              slug: item.slug
            })
          }
        }}
        >Choose</td>
      </>
    )
  }

  return (
    <div>
      <div className="row">
        <div className="col-sm-2 col-1"></div>
        <div className="col-sm-8 col-10 form-view mb-3">
          <h4><center>Batch Information</center></h4>
          <table>
            <tbody>
              <tr>
                <td className="col-3">Batch Name</td>
                <td className="col-6">
                  <input className="input-common"
                    value={Title} placeholder="Name"
                    onChange={(event) => { setTitle(event.target.value) }}
                  />
                </td>
              </tr>
              <tr>
                <td className="col-3">Batch Price</td>
                <td className="col-6">
                  <input className="input-common"
                    value={BatchPrice} placeholder="Price"
                    onChange={(event) => { setBatchPrice(event.target.value) }}
                  />
                </td>
              </tr>

              {/* <tr>
                <td className="col-3">Batch Status</td>
                <td className="col-6">
                  <CustomButton setData={setBatchStatus} Data={BatchStatus} DataValue={"Running"} />
                  <CustomButton setData={setBatchStatus} Data={BatchStatus} DataValue={"Ended"} />
                </td>
              </tr> */}
              <tr>
                <td className="col-3">Batch Type</td>
                <td className="col-6">
                  {Object.values(type_array).map((item, index) => (
                    <span key={index}>
                      <CustomButton setData={setType} Data={Type} DataValue={item} />
                    </span>
                  ))}
                </td>
              </tr>

              <>
                <tr>
                  <td className="col-3">Batch Category</td>
                  <td className="col-6"
                    onClick={() => {
                      setCategoryModal(!CategoryModal)
                    }}
                  >
                    <div className="row">
                      <div className="col-8">
                        <div className="input-common">
                          {CategoryName}&nbsp;
                        </div>
                      </div>
                      <div className="col-4"
                        onClick={() => {
                          setCategoryID("")
                          setCategoryName("")
                        }}
                      >
                        Clear
                      </div>
                    </div>
                  </td>
                </tr>
                {CategoryModal &&
                  <section className="modal" >
                    <div className="row">
                      <div className="col-md-3 col-1"></div>
                      <div className="col-md-6 col-10  shadow bg-white">
                        <div style={{ margin: " 10px", overflowX: 'scroll', border: "1px solid #999", padding: "9px" }}>
                          <div style={{ cursor: 'pointer', textAlign: 'right', padding: "0px 20px", fontWeight: "800" }}
                            onClick={() => {
                              setCategoryModal(!CategoryModal)
                            }}
                          >X</div>
                          <table>
                            <tbody>
                              {CategoriesList.map((item, index) => (
                                <tr className="row bg-white shadow" key={index}
                                  style={{ cursor: "pointer", border: "1px solid #999" }}
                                  onClick={() => {
                                    setCategoryID(`${item.id}`)
                                    setCategoryName(item.title.rendered)
                                    setCategoryModal(!CategoryModal)
                                    // setChaptersArray(ChaptersArray1.filter(a => a.meta.type))
                                  }}
                                >
                                  <td className="col-1">
                                    {CategoryID !== item.id ?
                                      <i className="fa fa-square-o" />
                                      :
                                      <i className="fa fa-check-square-o" />
                                    }
                                  </td>
                                  <td className="col-2" >
                                    {item.id}
                                  </td>
                                  <td className="col-3">
                                    {item.title.rendered}
                                  </td>
                                  <td className="col-3">
                                    {item.meta.batch_start_date}
                                  </td>
                                  <td className="col-3">
                                    {item.meta.batch_end_date}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </section>
                }
              </>

              {Type == type_array.Online_Class &&
                <>
                  <tr>
                    <td className="col-3">Zoom Link</td>
                    <td className="col-6">
                      <input className="input-common"
                        value={Zoom} placeholder="Zoom Link"
                        onChange={(event) => { setZoomLink(event.target.value) }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="col-3">Whatsapp Group</td>
                    <td className="col-6">
                      <input className="input-common"
                        value={WhatsappLink} placeholder="Whatsapp Group"
                        onChange={(event) => { setWhatsappLink(event.target.value) }}
                      />
                    </td>
                  </tr>
                </>
              }
            </tbody>
          </table>
          <div className="row center m-2">
            <div className=" col-3" style={{ padding: "0 10px" }}>
              <div className="sign-in-button-4"
                onClick={() => {
                  EditInfo()
                }}
              >
                Update Batch
              </div>
            </div>
          </div>
        </div>
      </div >
      <div className="row" >
        <div className="col-sm-2 col-1"></div>
        <div className="col-sm-8 col-10 form-view mb-3">
          <h4><center>Price List</center></h4>
          <table>
            <tbody>
              <tr>
                <td>Price For (in days)</td>
                <td>Price</td>
              </tr>


              {Object.values(BatchPriceList).map((item, index) => (
                <tr key={index}>
                  <td>
                    <input className="input-common-half" style={{ width: "100%", marginTop: 5 }}
                      value={item.price_duration} placeholder="Price Duration"
                      onChange={(event) => {
                        var pdf = BatchPriceList;
                        pdf = {
                          ...pdf,
                          [`item-${index}`]: {
                            "price_duration": `${event.target.value}`,
                            "price": `${item.page_number}`,
                          }
                        }
                        setBatchPriceList(pdf)
                      }}
                    />
                  </td>
                  <td>
                    <input className="input-common-half" style={{ width: "100%", marginTop: 5 }}
                      value={item.price} placeholder="Price"
                      onChange={(event) => {
                        var pdf = BatchPriceList;
                        pdf = {
                          ...pdf,
                          [`item-${index}`]: {
                            "price_duration": `${item.price_duration}`,
                            "price": `${event.target.value}`,
                          }
                        }
                        setBatchPriceList(pdf)
                      }}
                    />
                  </td>
                  <td style={{ cursor: "pointer" }} onClick={() => {
                    setBatchPriceIndex(`item-${index}`)
                    setBatchPriceDeleteModal(true)
                  }}>Delete</td>
                </tr>
              ))}
              <tr>
                <td style={{ cursor: "pointer" }} onClick={() => {
                  var pdf = BatchPriceList;
                  pdf = {
                    ...pdf,
                    [`item-${Object.keys(BatchPriceList).length}`]: {
                      "price_duration": "",
                      "price": "",
                    }
                  }
                  setBatchPriceList(pdf)
                }}>Add New</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div >
      {BatchPriceDeleteModal &&
        <section className="modal" >
          <div className="row">
            <div className="col-md-3 col-1"></div>
            <div className="col-md-6 col-10  shadow bg-white">
              <div style={{ margin: " 10px", overflowX: 'hidden', border: "1px solid #999", padding: "9px" }}>
                <div style={{ cursor: 'pointer', textAlign: 'right', padding: "0px 20px", fontWeight: "800" }}
                  onClick={() => {
                    setBatchPriceDeleteModal(false)
                  }}
                >X</div>
                Do you really want to delete Price?
                <table>
                  <tbody>
                    <tr>
                      <td>Price Duration : </td>
                      <td>
                        {BatchPriceList[BatchPriceDeleteIndex].price_duration}
                      </td>
                    </tr>
                    <tr>
                      <td>Price : </td>
                      <td>
                        {BatchPriceList[BatchPriceDeleteIndex].price}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div className="row" >
                  <div className="col-1 center " />
                  <div className="col-5 center " >
                    <div className="sign-in-button-4" style={{ width: "80%" }}
                      onClick={() => {
                        setBatchPriceDeleteModal(false)
                      }}
                    >
                      Cancel
                    </div>
                  </div>
                  <div className="col-5 center">
                    <div className="sign-in-button-4" style={{ width: "80%" }}
                      onClick={() => {
                        // Chapters.splice(ChaptersDeleteIndex, 1)
                        delete BatchPriceList[BatchPriceDeleteIndex]
                        setBatchPriceDeleteModal(false)
                      }}
                    >
                      OK
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      }
      {/* {JSON.stringify(BatchPriceList)} */}
      {/* {Type !== "First Paper" && */}
      < div className="row" >
        <div className="col-sm-2 col-1"></div>
        <div className="col-sm-8 col-10 form-view mb-3">
          <h4><center>Chapters List</center></h4>
          <table>
            <tbody>
              <tr>
                <td>Chapter ID</td>
                <td>Chapter Name</td>
                <td>Delete</td>
              </tr>

              {Chapters.map((item, index) => (
                <tr key={index}>
                  <td>{item.id}</td>
                  <td>{item.name}</td>
                  <td style={{ cursor: "pointer" }} onClick={() => {
                    setChaptersIndex(index)
                    setChaptersDeleteModal(true)
                    // BatchExam.splice(index, 1)
                  }}>Delete</td>
                </tr>
              ))}
              <tr>
                <td style={{ cursor: "pointer" }} onClick={() => {
                  setAddNewChapters(true)
                }}>Add New</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div >
      {/* } */}

      {ChaptersDeleteModal &&
        <section className="modal" >
          <div className="row">
            <div className="col-md-3 col-1"></div>
            <div className="col-md-6 col-10  shadow bg-white">
              <div style={{ margin: " 10px", overflowX: 'hidden', border: "1px solid #999", padding: "9px" }}>
                <div style={{ cursor: 'pointer', textAlign: 'right', padding: "0px 20px", fontWeight: "800" }}
                  onClick={() => {
                    setChaptersDeleteModal(false)
                  }}
                >X</div>
                Do you really want to delete Chapter?
                <table>
                  <tbody>
                    <tr>
                      <td>Chapter Name : </td>
                      <td>{Chapters[ChaptersDeleteIndex].name}</td>
                    </tr>
                    <tr>
                      <td>Chapter ID : </td>
                      <td>{Chapters[ChaptersDeleteIndex].id}</td>
                    </tr>
                  </tbody>
                </table>
                <div className="row" >
                  <div className="col-1 center " />
                  <div className="col-5 center " >
                    <div className="sign-in-button-4" style={{ width: "80%" }}
                      onClick={() => {
                        setChaptersDeleteModal(false)
                      }}
                    >
                      Cancel
                    </div>
                  </div>
                  <div className="col-5 center">
                    <div className="sign-in-button-4" style={{ width: "80%" }}
                      onClick={() => {
                        Chapters.splice(ChaptersDeleteIndex, 1)
                        setChaptersDeleteModal(false)
                      }}
                    >
                      OK
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      }
      {AddNewChapters &&
        <section className="modal" >
          <div className="row">
            <div className="col-md-3 col-1"></div>
            <div className="col-md-6 col-10  shadow bg-white">
              <div style={{ margin: " 10px", overflowX: 'scroll', border: "1px solid #999", borderRadius: "10px", padding: "9px" }}>
                <div style={{ cursor: 'pointer', textAlign: 'right', padding: "0px 20px", fontWeight: "800" }}
                  onClick={() => {
                    setAddNewChapters(false)
                  }}
                >X</div>
                <div className="col-md-6 row">
                  <div className="col-1" />
                  <input className="col-3" style={{ margin: "1px 2px", padding: "3px 2px" }}
                    value={serachName} placeholder="Name"
                    onChange={(event) => {
                      setSearchNameChapters(event.target.value)
                      setChaptersArray(ChaptersArray1.filter(a => a.title.rendered.toLowerCase().includes(event.target.value.toLowerCase())))
                    }}
                  />
                </div>

                <div style={{ height: "400px", overflowY: "scroll" }}>
                  <table>
                    <tbody>
                      <tr>
                        <td></td>
                        <td>ID</td>
                        <td>Name</td>
                        <td>Choose</td>
                      </tr>
                      {ChaptersArray.map((item, index) => (
                        <tr key={index}>
                          <MappingChapters item={item} />
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div style={{ padding: "10px" }}>
                  <div className="row">
                    <div className="col-1" />
                    <div className="col-5 center " >
                      <div className="sign-in-button-4" style={{ width: "80%" }}
                        onClick={() => {
                          setChaptersCache(Chapters)
                          setAddNewChapters(false)
                        }}
                      >
                        Cancel
                      </div>
                    </div>
                    <div className="col-5 center">
                      <div className="sign-in-button-4" style={{ width: "80%" }}
                        onClick={() => {
                          setChapters(ChaptersCache)
                          setAddNewChapters(false)
                        }}
                      >
                        Done
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </section>
      }

      {(Type == "MCQ" || Type == "Class") &&
        <div className="row">
          <div className="col-sm-2 col-1"></div>
          <div className="col-sm-8 col-10 form-view mb-3">
            <h4><center>Batch Exam List</center></h4>
            <table>
              <tbody>
                <tr>
                  <td>Exam ID</td>
                  <td>Exam Name</td>
                  <td>Exam Type</td>
                </tr>

                {BatchExam.map((item, index) => (
                  <tr key={index}>
                    <td>{item.exam_id}</td>
                    <td>{item.name}</td>
                    <td style={{ cursor: "pointer" }} onClick={() => {
                      setBatchExamIndex(index)
                      setBatchExamDeleteModal(true)
                      // BatchExam.splice(index, 1)
                    }}>Delete</td>
                  </tr>
                ))}
                <tr>
                  <td style={{ cursor: "pointer" }} onClick={() => {
                    setAddNew(true)
                  }}>Add New</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      }
      <div className="row">
        <div className="col-1"></div>
        <div className="col-10 form-view mb-3">
          <h4><center>Chapter Content</center></h4>

          <div>
            <div className="row center m-2">
              <div className=" col-3" style={{ padding: "0 10px" }}>
                <div className="sign-in-button-4"
                  onClick={() => {
                    setCourseEditData({
                      "chapter_number": `${Object.keys(CourseContent).length + 1}`,
                      "title": "",
                      "description": "",
                      "is_preview": "",
                      "chapter_type": "Video",
                      "video_link": "",
                      "youtube_id": "",
                      "content": "",
                      "time": "",
                      "pdf_url": "",
                      "pdf_list": "[]",
                      "audio_list": "[]",
                    })
                    setCourseEditKey(`item-${Object.keys(CourseContent).length}`)

                    setCourseContentEdit(true)
                  }}
                >
                  Add Content
                </div>
              </div>
            </div>
            <div>
              <div className="row m-4">
                <div>
                  <div className="row">
                    {Object.values(CourseContent).map((item, index) => {
                      return (
                        <div key={index} className="col-6">
                          <div
                            style={{
                              margin: "5px", padding: "5px 10px",
                              border: "2px solid black",
                              borderRadius: "10px",
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              setCourseEditData(item)
                              setCourseEditKey(Object.keys(CourseContent)[index])
                              setCourseContentEdit(true)

                            }}
                          >
                            <div style={{ fontWeight: "bold", }}>
                              {item.title}
                            </div>

                            <div style={{}}>
                              {item.description}
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            </div>

          </div>

        </div>
      </div>

      {
        CourseContentEdit &&
        <section className="modal" >
          <div className="row">
            <div className="col-md-2 col-1"></div>
            <div className="col-md-8 col-10  shadow bg-white">
              <div style={{ margin: " 10px", overflowX: 'scroll', border: "1px solid #999", padding: "9px" }}>
                <div style={{ cursor: 'pointer', textAlign: 'right', padding: "0px 20px", fontWeight: "800" }}
                  onClick={() => {
                    setCourseContentEdit(false)
                    setCourseEditData([])
                    setCourseEditKey("")
                  }}
                >X</div>

                <CourseUploadComp
                  oneChapterData={CourseEditData} setCourseEditData={setCourseEditData}
                  keyValue={CourseEditKey} setCourseEditKey={setCourseEditKey}
                  CourseContent={CourseContent} setCourseContent={setCourseContent}
                  setCourseContentEdit={setCourseContentEdit}
                  CourseType={"Book"}
                />
              </div>
            </div>
          </div>
        </section>
      }

      {BatchExamDeleteModal &&
        <section className="modal" >
          <div className="row">
            <div className="col-md-3 col-1"></div>
            <div className="col-md-6 col-10  shadow bg-white">
              <div style={{ margin: " 10px", overflowX: 'hidden', border: "1px solid #999", padding: "9px" }}>
                <div style={{ cursor: 'pointer', textAlign: 'right', padding: "0px 20px", fontWeight: "800" }}
                  onClick={() => {
                    setBatchExamDeleteModal(false)
                  }}
                >X</div>
                Do you really want to delete Exam?
                <table>
                  <tbody>
                    <tr>
                      <td>Exam Name : </td>
                      <td>{BatchExam[BatchExamDeleteIndex].name}</td>
                    </tr>
                    <tr>
                      <td>Exam ID : </td>
                      <td>{BatchExam[BatchExamDeleteIndex].exam_id}</td>
                    </tr>
                  </tbody>
                </table>
                <div className="row" >
                  <div className="col-1 center " />
                  <div className="col-5 center " >
                    <div className="sign-in-button-4" style={{ width: "80%" }}
                      onClick={() => {
                        setBatchExamDeleteModal(false)
                      }}
                    >
                      Cancel
                    </div>
                  </div>
                  <div className="col-5 center">
                    <div className="sign-in-button-4" style={{ width: "80%" }}
                      onClick={() => {
                        BatchExam.splice(BatchExamDeleteIndex, 1)
                        setBatchExamDeleteModal(false)
                      }}
                    >
                      OK
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      }
      {AddNew &&
        <section className="modal" >
          <div className="row">
            <div className="col-md-3 col-1"></div>
            <div className="col-md-6 col-10  shadow bg-white">
              <div style={{ margin: " 10px", overflowX: 'scroll', border: "1px solid #999", borderRadius: "10px", padding: "9px" }}>
                <div style={{ cursor: 'pointer', textAlign: 'right', padding: "0px 20px", fontWeight: "800" }}
                  onClick={() => {
                    setAddNew(false)
                  }}
                >X</div>
                <div className="col-md-6 row">
                  <div className="col-1" />
                  <input className="col-3" style={{ margin: "1px 2px", padding: "3px 2px" }}
                    value={serachName} placeholder="Name"
                    onChange={(event) => {
                      setSearchName(event.target.value)
                      setExamArray(ExamArray1.filter(a => a.title.rendered.toLowerCase().includes(event.target.value.toLowerCase())))
                    }}
                  />
                </div>

                <div style={{ height: "400px", overflowY: "scroll" }}>
                  <table>
                    <tbody>
                      <tr>
                        <td></td>
                        <td>Exam ID</td>
                        <td>Exam Name</td>
                        <td>Choose</td>
                      </tr>
                      {ExamArray.map((item, index) => (
                        <tr key={index}>
                          <MappingExam item={item} />
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div style={{ padding: "10px" }}>
                  <div className="row">
                    <div className="col-1" />
                    <div className="col-5 center " >
                      <div className="sign-in-button-4" style={{ width: "80%" }}
                        onClick={() => {
                          setBatchExamCache(BatchExam)
                          setAddNew(false)
                        }}
                      >
                        Cancel
                      </div>
                    </div>
                    <div className="col-5 center">
                      <div className="sign-in-button-4" style={{ width: "80%" }}
                        onClick={() => {
                          setBatchExam(BatchExamCache)
                          setAddNew(false)
                        }}
                      >
                        Done
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </section>
      }



      {reload &&
        <div className="modal" style={{ padding: "50vw" }}>
          <div className="dot-pulse"></div>
        </div>
      }
      {
        AlertShow &&
        <AlertComponent Alertcomp={Alertcomp} setAlertShow={setAlertShow} />
      }
    </div >
  )

}




const CourseUploadComp = ({
  oneChapterData, setCourseEditData,
  keyValue, setCourseEditKey, setCourseContentEdit,
  CourseContent, setCourseContent,
  CourseType
}) => {
  const [ChapterNumber, setChapterNumber] = React.useState(oneChapterData.chapter_number)
  const [Title, setTitle] = React.useState(oneChapterData.title)
  const [Description, setDescrition] = React.useState(oneChapterData.description)
  const [Time, setTime] = React.useState(oneChapterData.time)
  const [IsPreview, setIsPreview] = React.useState(oneChapterData.is_preview)
  const [VideoLink, setVideoLink] = React.useState(oneChapterData.video_link)
  const [YoutubeID, setYoutubeID] = React.useState(oneChapterData.youtube_id)
  const [ChapterType, setChapterType] = React.useState(oneChapterData.chapter_type)
  const [Content, setContent] = React.useState(oneChapterData.content)
  const [PDFURL, setPDFURL] = React.useState(oneChapterData.pdf_url)
  const [PdfList, setPdfList] = React.useState(JSON.parse(oneChapterData.pdf_list))
  const [AudioList, setAudioList] = React.useState(JSON.parse(oneChapterData.audio_list))
  const [AudioURL, setAudioURL] = React.useState("")
  const [PageNumber, setPageNumber] = React.useState("")
  const [TitleAudio, setTitleAudio] = React.useState("")

  function YouTubeGetID(url) {
    url = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
    if (url[2] !== undefined)
      setYoutubeID(url[2].split(/[^0-9a-z_\-]/i)[0])
    // console.log(url[2].split(/[^0-9a-z_\-]/i)[0])
    return (url[2] !== undefined) ? url[2].split(/[^0-9a-z_\-]/i)[0] : url[0];
  }
  return (
    <div>
      <div style={{ maxHeight: "60vh", overflowY: "scroll" }}>
        <table>
          <tbody>
            <tr>
              <td className="col-3">Day Number</td>
              <td className="col-6">
                {/* {ChapterNumber} */}
                <input className="input-common-4"
                  value={ChapterNumber} placeholder="Number"
                  onChange={(event) => { setChapterNumber(event.target.value) }}
                />
              </td>
            </tr>
            <tr>
              <td className="col-3">Title</td>
              <td className="col-6">
                <input className="input-common-4"
                  value={Title} placeholder="Chapter Title"
                  onChange={(event) => { setTitle(event.target.value) }}
                />
              </td>
            </tr>
            <tr>
              <td className="col-3">Description</td>
              <td className="col-6">
                <input className="input-common-4"
                  value={Description} placeholder="Description"
                  onChange={(event) => { setDescrition(event.target.value) }}
                />
              </td>
            </tr>
            <tr>
              <td className="col-3">Time</td>
              <td className="col-6">
                <input className="input-common-4"
                  value={Time} placeholder="Time"
                  onChange={(event) => { setTime(event.target.value) }}
                />
              </td>
            </tr>

            <>
              <tr>
                <td className="col-3">Video Link</td>
                <td className="col-6">
                  <input className="input-common-4"
                    value={VideoLink} placeholder="Video Link"
                    onChange={(event) => {
                      setVideoLink(event.target.value)
                      YouTubeGetID(event.target.value)
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td className="col-3">Youtube ID</td>
                <td className="col-6">
                  <input className="input-common-4"
                    value={YoutubeID} placeholder="Youtube ID"
                    onChange={(event) => { setYoutubeID(event.target.value) }}
                  />
                </td>
              </tr>
            </>
          </tbody>
        </table>
      </div>
      <center>
        <div style={{ width: "30%" }} className="sign-in-button-1"
          onClick={() => {
            setCourseContent({
              ...CourseContent,
              [keyValue]: {
                "chapter_number": `${ChapterNumber}`,
                "title": `${Title}`,
                "time": `${Time}`,
                "description": `${Description}`,
                "is_preview": `${IsPreview}`,
                "chapter_type": `${ChapterType}`,
                "video_link": `${VideoLink}`,
                "youtube_id": `${YoutubeID}`,
                "content": `${Content}`,
                "pdf_url": `${PDFURL}`,
                "pdf_list": `${JSON.stringify(PdfList)}`,
                "audio_list": `${JSON.stringify(AudioList)}`,
              }
            })
            setCourseEditKey(``)
            setCourseContentEdit(false)
          }}
        >Done</div>
      </center>
    </div >
  )
}